import React, { useEffect, useRef, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import "date-fns";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import { Box, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { Delete } from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import warningIcon from "../../assets/Icons/warningIcon.png";
import { InputAdornment, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import CalendarIcon from "../../assets/Icons/auftrage_calender.svg";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import DoneIcon from "@mui/icons-material/Done";
import swal from "sweetalert";
import GridViewIcon from "@mui/icons-material/GridView";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import CloseIcon from "@mui/icons-material/Close";
import { ThreeDots } from "react-loader-spinner";
import { promocodeService } from "../../_services/promo-code.service";
import Dialog from "@mui/material/Dialog";
import { userService } from "../../_services/user.service";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    /* "& .MuiDataGrid-main":{
      minHeight:"400px !important"
    }, */
    "& .MuiDataGrid-row": {
      borderBottom: "1px dashed rgba(151, 151, 151, 1)",
      maxHeight: "90px !important",
      minHeight: "90px !important",
      opacity:1
    },
    "& .MuiDataGrid-row:last-child": {
      borderBottom: "1px dashed rgba(151, 151, 151, 1)",
      maxHeight: "90px !important",
      minHeight: "90px !important",
      opacity:1
    },
    "& .MuiDataGrid-cell": {
      maxHeight: "inherit !important",
      minHeight: "inherit !important",
      height:"auto",
      border: "none !important",
    },
    "& .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar":
      {
        display: "flex",
        alignItems: "baseline",
        marginTop: "0.75rem",
        marginRight: "0.5rem",
      },
    paginationContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "10px",
      alignItems: "baseline",
    },
    paginationText: {
      marginRight: "5px",
      display: "inline",
    },
  },
}));
function OverviewPromoCode() {
  const classes = useStyles();
  const [errors, setErrors] = useState({});
  const [rows, setRows] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [sortModel, setSortModel] = useState([]);
  const [rowSelected, setRowSelected] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertAction, setAlertAction] = useState("");
  const [actionType, setAcitonType] = useState("");
  const [addPanel, setAddPanel] = useState(false);
  const [rowsData, setRowsData] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 15,
    page: 0,
  });
  const [rowsFiltered, setRowsFiltered] = useState([]);
  const [loading, setLoading] = useState(false);
  // Get today's date
  const today = new Date();
  // Calculate tomorrow's date by adding 1 day
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  //Generate new ID
  function generateID(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }
  const initialize = {
    id: "",
    promo_code: "",
    limit_users: "",
    users: [],
  };
  const [promoCodeObject, setPromoCodeObject] = useState(initialize);
  const [filterValue, setFilterValue] = useState("");
  const handleFilterChange = (event) => {
    if (filterValue) {
      const filteredArray = rows.filter((obj) => {
        return (
          moment
            .unix(obj.expiration_date?.seconds)
            .add(obj.expiration_date?.nanoseconds / 1000000)
            .format("DD.MM.YYYY")
            .includes(filterValue) ||
          obj.promo_code.toUpperCase().includes(filterValue.toUpperCase())
        );
      });
      setRowsFiltered(filteredArray);
      setRowsData(filteredArray)
    }
  };
  function handleChange(e, name) {
    setPromoCodeObject({
      ...promoCodeObject,
      [name]: e.target.value,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      promo_code_uppercase: false, // Set to true if not uppercase, false if uppercase
      [name]: false,
    }));
  }
  const handleChangeDate = (value, name = null) => {
    setPromoCodeObject({
      ...promoCodeObject,
      [name]: value,
    });
  };
  const isUpperCase = (str) => str === str.toUpperCase();

  //Validate required fields
  function validate() {
    const errors = {};
    const currentDate = new Date();
    currentDate.setUTCHours(currentDate.getUTCHours() + 2);
    if (!promoCodeObject.expiration_date) {
      errors.expiration_date = true;
    } else {
      const expirationDate = new Date(promoCodeObject.expiration_date);
      if (expirationDate < currentDate) {
        errors.expiration_date = true;
      }
    }
    if (!promoCodeObject.promo_code) {
      errors.promo_code = true;
    }
    if (!isUpperCase(promoCodeObject.promo_code)) {
      errors.promo_code = true;
      errors.promo_code_Uppercace = true;
    }
    if (parseInt(promoCodeObject.limit_users, 10) < -1) {
      errors.limit_users = true;
    }
    if (
      !promoCodeObject.limit_users ||
      promoCodeObject.limit_users === "" ||
      parseInt(promoCodeObject.limit_users, 10) === 0 ||
      parseInt(promoCodeObject.limit_users, 10) === NaN
    ) {
      setPromoCodeObject({
        ...promoCodeObject,
        limit_users: -1,
      });
    }
    if (parseInt(promoCodeObject.limit_users, 10) > 0) {
      setPromoCodeObject({
        ...promoCodeObject,
        limit_users: parseInt(promoCodeObject.limit_users, 10),
      });
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  }
  const onSubmit = async (event) => {
    const object = { ...promoCodeObject, id: generateID(36) };
    event.preventDefault();
    if (validate()) {
      try {
        promocodeService.createCodePromo(object).then((result) => {
          if (result) {
            swal({
              title: "HINWEIS",
              icon: "success",
              text: `Ihre Promotions wurde erfolgreich erstellt.`,
              button: "OK",
            }).then((willCreate) => {
              if (willCreate) {
                setAddPanel(false);
                getAll();
                setPromoCodeObject(initialize);
              }
            });
          } else {
            swal({
              title: "HINWEIS",
              icon: "error",
              text: "Ihre Promotions wurde erfolgreich erstellt.",
              button: "OK",
            }).then((willCreate) => {
              if (willCreate) {
                //window.location.href = "/#";
              }
            });
          }
        });
      } catch (err) {
        console.error(err);
      }
    } else {
      swal({
        title: "HINWEIS",
        icon: "error",
        text: "Bitte füllen Sie alle Felder aus!",
        button: "OK",
      }).then((willVerify) => {
        if (willVerify) {
          //window.location.href = "#";
        }
      });
    }
  };
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const handlegetUsers = async () => {
    try {
      const promises = users.map(async (userId) => {
        const userDoc = await userService.getUser(userId);
        if (!userDoc.empty) {
          // User exists in the database
          const userData = userDoc.docs[0].data(); // Assuming there's only one document for each user
          return {
            name: userData.first_name + " " + userData.last_name,
            email: userData.email,
          };
        } else {
          // User does not exist in the database
          return null;
        }
      });

      const results = await Promise.all(promises);
      setSelectedUsers(results);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  useEffect(() => {
    handlegetUsers();
  }, [users]);

  // Function to handle opening the modal and setting the selected users
  const handleOpenModal = async (users) => {
    if (users.length != 0) {
      setUsers(users);
      setModalOpen(true);
    }
  };
  // Column definitions
  const columns = [
    {
      field: "promo_code",
      headerName: "Promo Code",
      //description: "This column has a value getter and is not sortable.",
      sortable: false,
      disableColumnMenu: true,
      width: 100,
      flex: 0.3,
      renderCell: (params) => {
        const result = `${params.row.promo_code}`;
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "fit-content",
            }}
          >
            {result.toUpperCase()}
          </div>
        )
      },
    },
    {
      field: "limit_users",
      headerName: "Anzahl Plätze",
      //description: "This column has a value getter and is not sortable.",
      sortable: false,
      disableColumnMenu: true,
      width: 100,
      flex: 0.4,
      renderCell: (params) => {
        const result =
          params.row.limit_users === 0
            ? "Code vollständig genutzt."
            : params.row.limit_users > 0
            ? params.row.limit_users + " freie Plätze"
            : "Unbegrenzte Nutzer";

        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "fill-content",
            }}
          >
            {result}
          </div>
        );
      },
    },
    {
      field: "expiration_date",
      headerName: "Verfallsdatum",
      //description: "This column has a value getter and is not sortable.",
      sortable: false,
      disableColumnMenu: true,
      width: 100,
      flex: 0.3,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            className="auftrageCalendarIcon"
            src={CalendarIcon}
            alt="Auftrage Calendar Icon"
          />
          <span>
            {moment
              .unix(params?.row.expiration_date?.seconds)
              .add(params?.row.expiration_date?.nanoseconds / 1000000)
              .format("DD.MM.YYYY")}
          </span>
        </div>
      ),
    },
    {
      field: "creation_date",
      headerName: "Erstellungsdatum",
      //description: "This column has a value getter and is not sortable.",
      sortable: false,
      disableColumnMenu: true,
      width: 100,
      flex: 0.3,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            className="auftrageCalendarIcon"
            src={CalendarIcon}
            alt="Auftrage Calendar Icon"
          />
          <span>
            {moment
              .unix(params?.row.creation_date?.seconds)
              .add(params?.row.creation_date?.nanoseconds / 1000000)
              .format("DD.MM.YYYY")}
          </span>
        </div>
      ),
    },
    {
      field: "users",
      headerName: "Nutzer",
      //description: "This column has a value getter and is not sortable.",
      sortable: false,
      disableColumnMenu: true,
      width: 100,
      flex: 0.2,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            style={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={() => handleOpenModal(params.row.users)}
          >
            {params?.row.users.length + " Users"}
          </span>
        </div>
      ),
    },
    {
      field: "available",
      headerName: "Status",
      //description: "This column has a value getter and is not sortable.",
      sortable: false,
      disableColumnMenu: true,
      width: 100,
      flex: 0.4,
      renderCell: (params) => {
        // Get current date
        const currentDate = new Date();
        currentDate.setUTCHours(currentDate.getUTCHours() + 2);
        // Convert Firestore timestamp to JavaScript Date object
        const expirationDate = new Date(
          params?.row.expiration_date?.seconds * 1000 +
            params?.row.expiration_date?.nanoseconds / 1000000
        );
        let statusColor, statusIcon;
        let isExpired = (expirationDate < currentDate) || params.row.limit_users === 0;
        if (!isExpired) {
          statusColor = "#62AB12";
          statusIcon = (
            <DoneIcon style={{ marginRight: "5px", color: statusColor }} />
          );
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: statusColor,
                textTransform: "uppercase",
                width: "100%",
              }}
            >
              {statusIcon}
              {"Activ"}
            </div>
          );
        } else {
          statusColor = "#de0404";
          statusIcon = (
            <CircleIcon style={{ marginRight: "5px", color: statusColor }} />
          );
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: statusColor,
                textTransform: "uppercase",
                width: "100%",
              }}
            >
              {statusIcon}
              {"Expired"}
            </div>
          );
        }
      },
    },
  ];
  // Function to convert Unix timestamp to JavaScript Date object
const convertToDate = (timestamp) => {
  if (timestamp && timestamp.seconds) {
    return new Date(timestamp.seconds * 1000 + (timestamp.nanoseconds / 1000000));
  }
  return new Date(0); // Fallback if timestamp is missing
};
  async function handleDelete() {
    try {
      const promises = rowSelectionModel.map((rowId) => {
        return promocodeService.deletePromoCode(rowId);
      });

      await Promise.all(promises);

      // Delete the row from the grid
      const newRows = rows.filter((row) => !rowSelectionModel.includes(row.id));
      setRows(newRows);
      const newFilteredRows = rowsFiltered.filter(
        (row) => !rowSelectionModel.includes(row.id)
      );
      setRowsFiltered(newFilteredRows);
      setRowsData(newFilteredRows)
      setRowSelectionModel([]);
    } catch (error) {
      console.error("Error deleting promo code: ", error);
    }
  }
  const onCancel = () => {
    setShowAlert(false);
  };
  const handleConfirm = async () => {
    handleDelete();
    setShowAlert(false);
  };
  const handleDeleteOrArchived = (type) => {
    const selectedRows = rowSelectionModel.map((rowId) => {
      return rowsFiltered.find((row) => {
        return row.id === rowId;
      });
    });

    setRowSelected(selectedRows);
    if (type == "delete") {
      setAlertMessage("Möchten Sie diese Datensätze dauerhaft löschen ?");
      setAlertTitle("ACHTUNG");
      setAlertAction("Jetzt löschen");
      setAcitonType(type);
      setShowAlert(true);
    } else {
      setAlertMessage(
        "Wenn Sie die Datensätze archivieren, werden diese nicht mehr in Ihrer Übersicht angezeigt, sind aber im Archiv jederzeit wieder abrufbar."
      );
      setAlertAction("Archivieren");
      setAlertTitle("HINWEIS");
      setAcitonType(type);
      setShowAlert(true);
    }
  };

  const handleSelectionChange = (newRowSelectionModel) => {
    //rowSelectionModel.push(newRowSelectionModel);
    setRowSelectionModel(newRowSelectionModel);
  };
  const MycustomFooter = () => <></>;
  const getAll = async() => {
    await promocodeService.getAllPromoCode().then((result) => {
      const data=result
      // Sort the results by creation_date in descending order
      const sortedResult = data.sort((a, b) => {
      const dateA = convertToDate(a.creation_date);
      const dateB = convertToDate(b.creation_date);
      return dateB - dateA; // Newest to oldest
    });
      if(sortedResult)
      {setRows(result);
      setRowsFiltered(result);
      setRowsData(result)
      setLoading(true);}
    });
  };
  function generatePromoString() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const promoPrefix = "PROMO";
    let result = promoPrefix;

    for (let i = promoPrefix.length; i < 10; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }

    return result;
  }
  useEffect(() => {
    if (filterValue == "") {
      setRowsFiltered(rows);
      setRowsData(rows)
      
    }
  }, [filterValue]);
  useEffect(() => {
    getAll();
  }, []);
  const [currentIndex, setCurrentIndex] = useState(100); // Initially load 100 rows
  const [lastIndex, setLastIndex] = useState(0); 

  
  // State to manage visibility of "Load More" button
  const [hideLoadMore, setHideLoadMore] = useState(false);
  const [hideReset, setHideReset] = useState(false);

  // Load initial data (first 100 rows) when component mounts
  useEffect(() => {
    setRowsData(rowsFiltered.slice(lastIndex, currentIndex));
    setHideLoadMore(rowsFiltered.length <= currentIndex); // Hide "Load More" if no more data
    setHideReset(lastIndex === 0);
  }, [rowsFiltered, currentIndex]);

  // Handle "Load More" button click
  const handleLoadMoreClick = () => {
    setLastIndex(currentIndex)
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex + 100;
      setHideLoadMore(rowsFiltered.length <= newIndex); // Hide "Load More" if no more data
      return newIndex;
    });
  };
  // Handle "Reset" button click to go back to the first 100 rows
  const handleReset = () => {
    setCurrentIndex(100);
    setLastIndex(0)
    setHideLoadMore(rowsFiltered.length <= 100);
  };
  const [shrinkLabel, setShrinkLabel] = useState(false);
  const inputRefs = useRef([]);
  const handleInputClick = (e) => {
    const name = e.target.name;
    setShrinkLabel((prev) => ({ ...prev, [name]: true }));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      for (let i = 0; i < 20; i++) {
        if (
          inputRefs.current[i] &&
          !inputRefs.current[i].contains(event.target)
        ) {
          setShrinkLabel(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [promoCodeObject]);
  return (
    <>
      {addPanel && (
        <div className="layoutstepsbar" style={{ height: "70px" }}>
          <div class="row layoutcontent full-height mr-0 ml-0">
            <div className="einzug width ">
              <h3>X-CITE PROMOTION</h3>
            </div>
            <div className="Weiter align-end-button">
              <button
                onClick={() => {
                  setAddPanel(false);
                  setPromoCodeObject(initialize);
                }}
                className="WeiterButton"
                type="submit"
              >
                Zurück
                <ArrowForwardIosIcon
                  className="icon-registrieren"
                  sx={{ fontSize: 16 }}
                />
              </button>
            </div>
          </div>
        </div>
      )}
      {!addPanel && (
        <div className="layoutstepsbar height-auto">
          <div className="row layoutcontent full-height mr-0 ml-0">
            <div className="col-md-3 pr-0 pl-0">
              <div className="einzug h-full w-100">
              <div style={{display: "flex",alignItems: "flex-end",width:"100%"}}>
                <GridViewIcon sx={{    fontSize: 20, width: "25px",
                  height: "25px",
                  marginRight: "0px"}} />
                <h3 style={{ textTransform: "uppercase" }}>
                  Promotions ÜBERSICHT
                </h3>
                </div>
              </div>
            </div>
            <div className="col-md-6 search-contain pr-0 pl-0">
              <div className="search-padding promocode-search">
                <TextField
                  InputLabelProps={{
                    style: {
                      textTransform: "uppercase",
                      padding: "0 5px 0 0",
                      backgroundColor: "white",
                    },
                  }}
                  //id="auftrage_search-bar"
                  placeholder="Suche: Ticketnummer, Datum, E-Mailadresse"
                  //variant="outlined"
                  sx={{
                    width: 450,
                    background: "white",
                    borderRadius: "5px",
                    border: "none",
                  }}
                  value={filterValue}
                  onChange={async (event) => {
                    await setFilterValue(event.target.value.toString());
                    handleFilterChange(event);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: "#009fe3" }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setFilterValue("");
                            setRowsFiltered(rows);
                          }}
                        >
                          <ClearIcon sx={{ color: "#009fe3" }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <div className="col-md-3  pr-0 pl-0 d-flex align-items-center">
              <div
                className="New-promocode Weiter"
                style={{ marginLeft: "auto" }}
              >
                <button
                  onClick={() => setAddPanel(true)}
                  className="WeiterButton icon-promocode"
                  type="submit"
                  style={{ fontSize: "18px " }}
                >
                  Neu
                  <AddOutlinedIcon sx={{ fontSize: 30 }} />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {addPanel && (
        <div className="outer-container">
          <div className="card-style">
            <div className="SonCard">
              <div className="card-shadow">
                <div className="after-cards">
                  <div className="textfieldsdateplzObOw">
                    <p>
                      Geben Sie den Promotionscode ein, den Sie von der X-SMART
                      erhalten haben
                    </p>
                    <div className="row mt-2 mb-2">
                      <div className="col-md-3">
                        <TextField
                          onClick={() => handleInputClick}
                          inputRef={(el) => (inputRefs.current[0] = el)}
                          InputLabelProps={{
                            style: {
                              textTransform: "uppercase",
                              padding: "0 5px 0 0",
                              backgroundColor: "white",
                            },
                            shrink:
                              (shrinkLabel.promo_code &&
                                promoCodeObject &&
                                promoCodeObject.promo_code) ||
                              (!shrinkLabel.promo_code &&
                                promoCodeObject &&
                                promoCodeObject.promo_code) ||
                              (shrinkLabel.promo_code &&
                                promoCodeObject &&
                                (promoCodeObject.promo_code === null ||
                                  promoCodeObject.promo_code === "")),
                          }}
                          name="promo_code"
                          id="promo_code"
                          value={promoCodeObject?.promo_code}
                          type="promo_code"
                          label="Ihr Promotionscode"
                          variant="outlined"
                          fullWidth
                          onChange={(e) => handleChange(e, "promo_code")}
                          required
                          error={errors.promo_code}
                        />
                        {errors.promo_code_Uppercace && (
                          <div className="text-danger error">
                            Fehler: Der Promotionscode sollte nur aus
                            Großbuchstaben bestehen.
                          </div>
                        )}
                        <span
                          className="randomCodePromo"
                          onClick={() => {
                            setPromoCodeObject((prev) => ({
                              ...prev,
                              promo_code: generatePromoString(),
                            }));
                          }}
                        >
                          click to generate Promotionscode
                        </span>
                      </div>
                      <div className="col-md-3">
                        <TextField
                          // onClick={() => handleInputClick}
                          // inputRef={(el) => (inputRefs.current[1] = el)}
                          onFocus={() =>
                            setShrinkLabel((prev) => ({
                              ...prev,
                              limit_users: true,
                            }))
                          }
                          onBlur={() =>
                            setShrinkLabel((prev) => ({
                              ...prev,
                              limit_users:
                                promoCodeObject.limit_users === ""
                                  ? false
                                  : true,
                            }))
                          }
                          InputLabelProps={{
                            style: {
                              textTransform: "uppercase",
                              padding: "0 5px 0 0",
                              backgroundColor: "white",
                            },
                            shrink:
                              (shrinkLabel.limit_users &&
                                promoCodeObject &&
                                promoCodeObject.limit_users) ||
                              (!shrinkLabel.limit_users &&
                                promoCodeObject &&
                                promoCodeObject.limit_users) ||
                              (shrinkLabel.limit_users &&
                                promoCodeObject &&
                                (promoCodeObject.limit_users === null ||
                                  promoCodeObject.limit_users === "")),
                          }}
                          name="limit_users"
                          id="limit_users"
                          value={promoCodeObject?.limit_users}
                          type={
                            promoCodeObject?.limit_users === ""
                              ? "text"
                              : "number"
                          }
                          label={
                            !shrinkLabel.limit_users
                              ? "Unbegrenzt"
                              : "Benutzer beschränken"
                          }
                          variant="outlined"
                          fullWidth
                          onChange={(e) => handleChange(e, "limit_users")}
                          error={errors.limit_users}
                        />
                      </div>
                      <div className="col-md-6">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            className="w-full"
                            id="letzte"
                            label="ABLAUFDATUM"
                            name="expiration_date"
                            inputFormat="DD.MM.YYYY"
                            error={errors.expiration_date}
                            value={promoCodeObject?.expiration_date || null}
                            required
                            //error={errors.expiration_date}
                            onChange={(e) => {
                              handleChangeDate(e, "expiration_date");
                            }}
                            minDate={tomorrow} // Set minimum selectable date to tomorrow
                            renderInput={(params) => {
                              return (
                                <TextField
                                  onClick={() => handleInputClick}
                                  inputRef={(el) => (inputRefs.current[2] = el)}
                                  autoComplete="off"
                                  required
                                  inputlabelprops={{
                                    style: {
                                      textTransform: "uppercase",
                                      padding: "0 5px 0 0",
                                      backgroundColor: "white",
                                    },
                                    shrink:
                                      (shrinkLabel.expiration_date &&
                                        promoCodeObject &&
                                        promoCodeObject.expiration_date) ||
                                      (!shrinkLabel.expiration_date &&
                                        promoCodeObject &&
                                        promoCodeObject.expiration_date) ||
                                      (shrinkLabel.expiration_date &&
                                        promoCodeObject &&
                                        (promoCodeObject.expiration_date ===
                                          null ||
                                          promoCodeObject.expiration_date ===
                                            "")),
                                  }}
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "TT.MM.JJJJ",
                                  }}
                                />
                              );
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                  <div className="OvwerviewGlobalButtonSUP col-12 w-100">
                    <button type="button" onClick={onSubmit}>
                      {/* Versenden */}
                      SPEICHERN
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        )}
        {!addPanel && (
          <div className="outer-container">
           <div className="card-style">
            <div className="SonCard">
            <Box className={classes.root}>
              {loading && rowsFiltered.length > 0 ? (
                <>

                  <div className="row mr-0 ml-0">
                        <div className="col-md-12 pr-0 pl-0">
                          <div className="neuer ">
                          <GridViewIcon sx={{ fontSize: 40, marginRight: "1rem" }} />
                            <h3 style={{ textTransform: "uppercase" }}>
                               Promotions ÜBERSICHT ( {rowsFiltered.length} Ergebnisse)
                            </h3>
                          </div>
                        </div>
                      </div>
                  <div style={{ width: "100%" }}>
                  <div
                    hidden={rowSelectionModel.length > 0 ? false : true}
                    className="contain_button_dashboard"
                  >
                    <button
                      className={`button_delete_dashboard`}
                      onClick={() => handleDeleteOrArchived("delete")}
                    >
                      <Delete /> <span>Löschen</span>
                    </button>
                  </div>
                    <DataGrid
                      id="auftrage_grid"
                      rows={rowsData}
                      columns={columns}
                      customFooter={<MycustomFooter />}
                      selection={false}
                      checkboxSelection
                      //pagination
                      //pageSize={paginationModel.pageSize}
                      //pagination
                      autoHeight={true}
                      getRowHeight={() => 'auto'}
                      paginationMode="server"
                      //page={paginationModel.page}
                      /*onPageChange={(params) =>
                        setPaginationModel((prevModel) => ({
                          ...prevModel,
                          page: params.page,
                        }))
                      }*/
                      align="center"
                      sx={{
                        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                          display: "none",
                        },
                      }}
                      /*componentsProps={{
                        header: {
                          cellClassName: classes.headerCell,
                        },
                        pagination: {
                          labelRowsPerPage: "ZEILEN",
                        },
                      }}*/
                      sortModel={sortModel}
                      onSortModelChange={(newSortModel) =>
                        setSortModel(newSortModel)
                      }
                      onRowSelectionModelChange={handleSelectionChange}
                      disableRowSelectionOnClick
                      rowSelectionModel={rowSelectionModel}
                      hideFooterPagination={true}
                      hideFooterSelectedRowCount={true} 
                      hideHeader={true}
                      headerHeight={0}
                      hideFooterRowCount={false}
                      disableSelectionOnClick
                      hideColumnHeaders={true}
                      classes={{
                        columnHeaders: "my-custom-column-headers",
                      }}
                      //disableSelectionOnClick
                    />
        <div className="row mt-2 mb-2" style={{ justifyContent: "center" }}>
          {!hideLoadMore && ( <div className="col-md-4 d-flex justify-content-center">
            <div className="weiter-protocolltype ">
              <button
                type="button"
                onClick={handleLoadMoreClick}
                className="w-half"
                style={{ width: "100%" }}
              >
                MEHR ...
              </button>
            </div>
          </div>)}
          {!hideReset && ( <div className="col-md-4 d-flex justify-content-center">
          <div className="weiter-protocolltype">
            <button
              type="button"
              onClick={handleReset}
              className="w-half"
              style={{ width: '100%' }}
            >
              RESET
            </button>
          </div>
        </div>)}
        </div>
                  </div>
                </>
              ) : loading && rowsFiltered.length == 0 ? (
                <div className="col-md-12" style={{ backgroundColor: "#fff" }}>
                  <div
                    className="no-data-auftrage"
                    style={{ textTransform: "uppercase" }}
                  >
                    Kein offener Auftrag
                  </div>
                </div>
              ) : (
                <div className="col-md-12">
                  <div className="align-items-list">
                    <ThreeDots
                      height={80}
                      width={80}
                      color="black"
                      visible={true}
                    />
                  </div>
                </div>
              )}
              {selectedUsers?.filter(user => user !== null).length > 0 && (
                <Dialog
                  open={modalOpen}
                  onClose={() => {
                    setModalOpen(false);
                    setSelectedUsers([]);
                    setUsers([]);
                  }}
                  sx={{
                    "& .MuiDialog-paper": {
                      minWidth: "900px",
                      maxHeight: "sm",
                      width:"auto !important",
                      borderRadius: "15px",
                      backgroundColor: "#F3F3F3",
                      overflowY:"unset"
                    },
                  }}
                >
                  <div className="DialogDuplicate d-flex">
                        <div style={{ marginLeft: "auto" }}>
                            <button autoFocus onClick={() => {
                                                setModalOpen(false);
                                                setSelectedUsers([]);
                                                setUsers([]);
                                               }}>
                                 <HighlightOffIcon
                                   sx={{
                                     fontSize: 33,
                                     color: "#000000",
                                     //margin: "15px",
                                      }}
                                   />
                             </button>
                        </div>
                  </div>
                  <DialogContent
                    className="dialogPromocode"
                    style={{ border: "none",marginTop:"50px",marginBottom:"20px" }}
                    dividers
                    sx={{ minHeight: "250px" }}
                     >

                  {selectedUsers.map((user, i) => (
                    (user !==null ) &&
                    <><div key={i} className="row ml-0 mr-0" style={{ margin: "10px 0px" }}>
                      <div className="col-md-6 d-flex align-items-center">
                      <PersonIcon style={{ marginRight: "10px" }} /><p className="mb-0">{user.name}</p>
                      </div>
                      <div className="col-md-6 d-flex align-items-center">
                      <EmailIcon style={{ marginRight: "10px" }}/> <p className="mb-0">{user.email}</p>
                      </div>
                    </div>
                    <hr className="hrOvwerview" />
                    </>
                  ))}
                </DialogContent>
                </Dialog>
              )}
              {showAlert && (
                <SweetAlert
                  custom
                  showCancel
                  showCloseButton
                  confirmBtnText={alertAction}
                  cancelBtnText="Abbrechen"
                  confirmBtnBsStyle="primary"
                  cancelBtnBsStyle="light"
                  title={alertTitle}
                  customIcon={warningIcon}
                  onConfirm={handleConfirm}
                  onCancel={onCancel}
                >
                  {alertMessage}
                </SweetAlert>
              )}
            </Box>
          </div>
          </div>
          </div>
        )}

    </>
  );
}

export default OverviewPromoCode;
